import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./style.module.scss"
import Image from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faLinkedin, faStackOverflow, faTwitter } from "@fortawesome/free-brands-svg-icons"

const SocialIcon = ({ icon, link }) => {
  return (
    <div className={styles.socialIcon}>
      <a href={link}>
        <FontAwesomeIcon icon={icon} size={"2x"}/>
      </a>
    </div>
  )
}

const Index = () => {
  const { markdownRemark, img } = useStaticQuery(graphql`
{
  markdownRemark(frontmatter: {category: {eq: "about"}}) {
    html
  }
  img: file(absolutePath: { regex: "/profile_pic.jpeg/" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
}
  `)

  return <div>
    <div className={styles.imgContainer}>
      <Image className={styles.img} fixed={img.childImageSharp.fixed}/>
    </div>
    <div className={styles.aboutText}
         dangerouslySetInnerHTML={{
         __html: markdownRemark.html
       }}
    />
    <div className={styles.socialContainer}>
      <SocialIcon icon={faTwitter} link={"https://twitter.com/bilikdavid"}/>
      <SocialIcon icon={faGithub} link={"https://github.com/davidbilik"}/>
      <SocialIcon icon={faLinkedin} link={"https://linkedin.com/in/david-bilík-8ba48155"}/>
      <SocialIcon icon={faStackOverflow} link={"https://stackoverflow.com/users/1478296/billda"}/>
    </div>
  </div>
}


export default Index
