import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../components/about';

const AboutPage = ({location}) => {
  return (
    <Layout location={location} title={'About'}>
      <SEO title="About" />
      <About />
    </Layout>
  );
};

export default AboutPage;
